import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get } from "../store/action/request";
import { EVENT } from "../store/apiUrls";
import Menu, { Media } from "../components/Menu";
import FAQ from "../components/FAQ";
import { StoreBtn } from "./Home";
import { useSelector } from "react-redux";
import { Button } from "../components/tools/formTools";


const Event = ()=>{
    const {slug} = useParams();
    const [event, setEvent] = useState();
    const {isLoading} = useSelector(state=>state.request);
    const [error, setError] = useState(false);

    useEffect(()=>{
        get({
            url: EVENT(slug),
            success: setEvent,
            key: "event",
            error: ()=>setError(true)
        })
    }, [slug])

    useEffect(()=>{

        if(!event) return;

        document.title = event.title;

        // Changement de la balise meta description
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if(metaDescriptionTag) {
          metaDescriptionTag.content = event.description;
        }
    }, [event])
    
    const  openApp = useCallback(()=>{
    	let url = `xedo://event/${slug}`

    	window.location.href = url
    	
    }, [slug])

    // console.log(event)

    return (
        <div className="event-wrapper">
            <div className="event">
                <Menu/>

                <div className="page-container">
                    {event? (
                        <div className="card">
                            <div className="img">
                                <img src={event.img} alt={slug}/> 
                            </div>
                            <div className="event-content">
                                <h2>{event.title}</h2>
                                {event.description.split('\n').map(p=><p>{p}</p>)}


								<div style={{marginBottom: 20}}>
									<h4>Tickets disponible sur l'application Xèdo?</h4>
	                                <Button onClick={openApp}>
	                                	<strong>Réserver une place</strong>
	                                </Button>
								</div>
								
								<div style={{marginBottom: 20}}>
									<h4>Vous n'avez pas l'application Xèdo?</h4>
	                                <StoreBtn/>
								</div>


                            </div>
                        </div>

                    ): isLoading.event?(
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div className="loading"></div>
                        </div>
                    ): error && (
                        <h2 style={{textAlign: "center"}}>Evènement non retrouvé. Merci de vérifié le lien!</h2>
                    )}
                </div>

                <footer>
                    <FAQ/> 
                            
                    <div className="container">
                        <h3>Qu’attendez vous ? Téléchargez maintenant!</h3>
                        <StoreBtn/>
                        
                        <div className="bottom">
                            <div>
                                <div className="logo2 white"></div>
                                <small> &copy; Copyright 2023</small>
                            </div>
                            <div>
                                <Link to="/readme?to=condition">Conditions d'utilisation</Link>
                                <Link to="/readme?to=politique">Politique de confidentialité</Link>
                                <Media/>
                            </div>
                        </div>

                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Event;
