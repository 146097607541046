import {useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom"
import { AnimatePresence, motion } from "framer-motion";


const Modal = ({show=false, setShow, children, onClose=()=>{}})=>{
    const ref = useRef();
    const chld = useRef();

    useLayoutEffect(()=>{
        function f(){
            if(!chld.current) return;
            chld.current.style.maxHeight = (window.innerHeight - 100) + "px";
            console.log(chld.current)
            
        }

        f();
        window.addEventListener("resize", f);

        return ()=> window.removeEventListener("resize", f);
    }, [chld])

    return createPortal(
        
        <AnimatePresence>
            {show && (
                <motion.div 
                    className="modal" ref={ref}
                    exit="exit"
                    animate="enter"
                    initial="exit"

                    variants={{
                        exit:{
                            opacity: 0,
                        },
                        enter: {
                            opacity: 1,
                            backdropFilter: "blur(1px)"
                        }
                    }}
                    transition={{duration: .5}}
                >
                    <motion.div 
                        className="container"
                        variants={{
                            exit:{
                                scale: 0
                            },
                            enter: {
                                scale: 1,
                                transition:{ stiffness: 200, type: "spring"}
                            }
                        }}
                        // transition={{type: "spring", }}
                    >
                        <motion.div style={{zIndex: 233}}>
                            <span onClick={()=>setShow(false)}>&times;</span>
                            <div className="children" ref={chld}>
                                {children}
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
        , document.body
    )
}

export default Modal;