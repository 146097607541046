import { Link, useSearchParams } from "react-router-dom";
import FAQ from "../components/FAQ";
import Menu, { Media } from "../components/Menu";
import { useCallback, useState, useEffect } from "react";
import { motion } from "framer-motion";
import Modal from "../components/Modal";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/action/notification";
import freader from "../components/tools/formReader";
import { post } from "../store/action/request";
import { CONTACT } from "../store/apiUrls";
// import { parsePhoneNumber } from 'react-phone-number-input'
import { Button, Input, PhoneNumber } from "../components/tools/formTools";

const Home = ()=>{

    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const [page, setPage] = useState("home")
    const [contact, setContact] = useState(false);
    const [number, setNumber] = useState("");

    const scrool = useCallback(to=>{
        if(to === "contact-us"){
            setContact(true);
            return;
        }
        let elm = document.querySelector("#"+to) || document.querySelector("#home")
        let container = document.querySelector(".home")
        
        elm && container.scrollTo({top: elm.offsetTop - 80, behavior: 'smooth',})
    }, [])

    const onClick = useCallback(tg=>{
        const i = tg.split("to=")
        scrool(i[1])
    }, [scrool])

    useEffect(()=>{

        const to = search.get("to") || "home";
        setPage(to);
        scrool(to);

    }, [search, scrool])

    const submit = useCallback(e=>{
        e.preventDefault();

        let data = freader(e.target, ["fullName", "email", 'object', 'message']);
        data.phone_number = number;

        dispatch(post({
            url: CONTACT,
            data,
            key: "contact-us",
            success: ()=>{
                dispatch(addNotification({title: "Succès", message: "Merci de contacter Xèdo. Nous vous repondrons dans un instant."}, 4000))
                setContact(false);
            }
        }))
    }, [dispatch, number])

    return (
        <div className="home">
            <Menu onClick={onClick} page={page}/>

            <div className="welcome-wrapper" id="home">
                <div className="motif-0-wrapper">
                    <div className="motif-0">
                        <div className="dec"></div>
                        <div className="dec dec-inverse"></div>
                        <div className="s1"></div>
                    </div>
                </div>


                <motion.div 
                    className="welcome"
                    variants={variants}
                    initial="free"
                    whileInView="enter"
                    viewport={{once: true}}
                    transition={{duration: 1, delay: .5}}
                >
                    <div className="title">
                        <h3>Imaginez votre quotidien avec 
                            <span style={{display: "inline-block"}} className="logo"></span>
                        </h3>
                    </div>
                    <p>
                        Découvrez comment Xèdo révolutionne la façon dont vous gérez votre argent et profitez d'événements mémorables.
                    </p>
                    <motion.div onViewportEnter={()=>setPage("home")}/>
                    <StoreBtn/>
                </motion.div>

                <motion.div 
                    className="counter row"
                    variants={variants}
                    initial="free"
                    whileInView="enter"
                    viewport={{once: true}}
                    transition={{duration: 1.5, delay: 1}}
                >
                    <div className="item">
                        <strong>+00M</strong>
                        <div>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cloud-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                <path fillRule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                            </svg>
                            <span>Téléchargements</span>
                        </div>
                    </div>
                    <div className="item">
                        <strong>+00M</strong>
                        <div>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                            </svg>
                            <span>Transactions</span>
                        </div>
                    </div>
                    <div className="item">
                        <strong>+00M</strong>
                        <div>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-star" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
                            </svg>
                            <span>Notes</span>
                        </div>
                    </div>
                </motion.div>

            </div>

            <div className="fonctionnality" id="fonctionnality">
                <div className="container">
                    <h1>Fonctionnalités</h1>
                    <motion.div onViewportEnter={()=>setPage("fonctionnality")}/>
                    {fontionnality.map((v, k)=>(
                        <motion.div variants={variants} 
                            className={"item item"+(k+1)} key={k}
                            initial="free"
                            whileInView="enter"
                            viewport={{once: true}}
                            transition={{duration: 1.5}}
                        >
                            <div className="text">
                                <h4>{v.title}</h4>
                                <p>{v.desc}</p>
                            </div>
                            <motion.div onViewportEnter={()=>setPage("fonctionnality")}/>
                            <div className="img-wrapper">
                                <div className="bg"></div>
                                <div className="img"></div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>

            <footer>
                <motion.div onViewportEnter={()=>setPage("faq")}/>
                <FAQ/> 
                        
                <div className="container">
                    <h3>Qu’attendez vous ? Téléchargez maintenant!</h3>
                    <StoreBtn/>
                    
                    <div className="bottom">
                        <div>
                            <div className="logo2 white"></div>
                            <small> &copy; Copyright 2023</small>
                        </div>
                        <div>
                            <Link to="/readme?to=condition">Conditions d'utilisation</Link>
                            <Link to="/readme?to=politique">Politique de confidentialité</Link>
                            <Media/>
                        </div>
                    </div>

                </div>
            </footer>

            <Modal setShow={setContact} show={contact}>
                        
                <form onSubmit={submit}> 
                    <h4>Aviez-vous des préocupations ?</h4>
                    <p>
                        <motion.span onViewportEnter={()=>setPage("contact-us")} onViewportLeave={()=>setPage("home")}/>

                        Notre équipe est là pour répondre à vos questions et vous guider. 
                    </p>
                    <Input label="Nom et Prénoms" required name="fullName" pk="full_name"/>
                    <Input label="Email"  type="email" required  name="email" pk="email"/>
                    {/* <Input label="Numéro de téléphone" required name="phoneNumber" pk="phone_number" placeholder="+229 90 80 82 83" /> */}
                    <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="client_phone_number" value={number} setValue={setNumber} />
                    
                    <Input label="Objet"  required  name="object" pk="object"/>
                    <Input label="Votre message" required type="textarea" name="message" pk="message" />
                    
                    <Button pk="contact-us">Envoyer</Button>

                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", gap: 10}}>
                        <a rel="noreferrer" href="mailto://smartentreprisebenin@gmail.com" target="_blank">
                            <strong>smartentreprisebenin@gmail.com</strong>
                        </a>

                        <a rel="noreferrer" href="https://wa.me/+22990808283" target="_blank">
                            <strong>+229 90 80 82 83</strong>
                        </a>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export const StoreBtn = ()=>{
    return (
        <div className="store-btn btn-box">

            <motion.div 
                className="store as" 
                onClick={()=>alert("Bientôt disponible")}

                // onClick={()=>dispatch(
                //     addNotification({
                //         id: "apple", title: "Oups !!!", message: "L'application sera disponible très bientôt sur apple store"
                //     }, 3000)
                // )}
                initial={{scale: .95}}
                whileTap={{scale: .95}}
                whileHover={{scale: 1.05}}
                whileInView={{scale: 1, transition:{delay: .3}}}
                transition={{type: "spring", stiffness: 400}}

            ></motion.div>
            <motion.div 
                className="store ps" 
                // onClick={()=>window.location.href = "https://play.google.com/store/apps/details?id=com.smartclub.caisa"}
                onClick={()=>alert("Bientôt disponible")}
                initial={{scale: .95}}
                whileTap={{scale: .95}}
                whileHover={{scale: 1.1}}
                whileInView={{scale: 1, transition:{delay: .5}}}
                transition={{type: "spring", stiffness: 400}}
                
            ></motion.div>
        </div>
    )
}

const variants = {
    free: {
        opacity: 0,
        y: 100
    },
    enter: {
        opacity: 1,
        y: 0
    }
}

const fontionnality = [
    {
        title: "Vos proches encore plus proche que vous l’imaginez",
        desc: "Imaginez pouvoir envoyer l’argent à vos proches directement dans leur compte mobile money quelque soit leur pays de résidence ou leur réseau mobile money"
    },
    {
        title: "Accessibilité aux évènements en un clic",
        desc: "Imaginez pouvoir acheter, stocker, sécuriser et partager les tickets de vos évènements préférés de manière dématérialisé sans frais cachés et sans risques de pertes"
    },
    {
        title: "Coup de pouce aux micro-entrepreneurs ",
        desc: "Imaginez pouvoir encaisser de manière professionnelle vos frais de services à travers une multitude de moyens de paiements disponible dans plusieurs pays"
    },
    {
        title: "Le Guichet portable par excellence",
        desc: "Imaginez pouvoir manager votre évènement en temps réel en générant, en cryptant automatiquement les tickets de vos évènements tout en ayant accès en temps réel aux données statistiques et comptables de votre évènement "
    }
]

export default Home;
