import { motion} from "framer-motion";
import { useState} from "react";
import HideChildren from "./HideChildren";
import { Link } from "react-router-dom";

const FAQ = ()=>{

    const [opened, setOpened] = useState();

    return (
        <div className="faq" id="faq">
            <div className="faq-body">
                <h2>FAQ</h2>
                <div className="wrapper">

                    <motion.div className="container"
                    >
                        <motion.div  
                            layout
                            whileInView="enter"
                            initial="free"
                            transition={{staggerChildren: .21, delayChildren: .5}}
                            >
                            {faq.map((item, k) =>{ 

                                return (
                                    <motion.div
                                        variants={{
                                            free:{
                                                opacity: 0,
                                                y: 30,
                                                scale: .5,
                                            },
                                            enter:{
                                                opacity:  1,
                                                y: 0,
                                                scale: 1
                                            },
                                        }}
                                        key={k}
                                    >

                                        <HideChildren 
                                            title={<h4>{item.ask}</h4>} 
                                            open={opened} name={k} toggleOpen={setOpened}
                                        >
                                            <div className="answer">{item.answer}</div>
                                        </HideChildren>
                                    </motion.div>
                                )       
                            })}
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}


const faq = [
    {
        ask: "Comment puis-je télécharger l'application Xèdo ?",
        answer: <>Vous pouvez télécharger l'application Xèdo sur l'App Store pour les appareils iOS et sur Google Play pour les appareils Android. Recherchez simplement "Xèdo" dans la boutique d'applications de votre choix et suivez les instructions pour l'installation.</>
    },
    {
        ask: "Est-ce que Xèdo est sécurisé ?",
        answer: <>Oui, Xèdo est construit avec la sécurité en tête. Nous mettons en œuvre:
            <ul>
                <li>une close de confidentialité assurer la protection de vos données personnelles</li>
                <li>une protocole d'authentification par approbation pour assurer votre accord de connexion</li>
                <li>une signature numérique de vos buillets vous permettant d'invalidé tout billet en cas de soupçon de vole ou d'exposition du code qr.</li>
                <li>une historique de passage en temps réel afin d'éviter la double utilisation des billets </li>
            </ul>
            Nous vous invitons à lire notre <span> </span>
            <Link to="/readme?to=condition"> Conditions d'utilisation</Link> <span> </span> et <span> </span>
            <Link to="/readme?to=politique">Politique de confidentialité</Link>
        </>
    },
    {
        ask: "Puis-je effectuer des transferts d'argent vers d'autres pays avec Xèdo ?",
        answer:  <>Absolument. Xèdo prend en charge les transferts d'argent en <strong>interopérabilité</strong> entre les différents réseaux mobiles Money dans plusieurs pays d'Afrique, ce qui vous permet d'envoyer de l'argent à l'étranger en toute simplicité.</>
    },
    {
        ask: "Comment puis-je acheter des billets pour un événement à l'aide de Xèdo ?",
        answer: <>
            Pour acheter des billets d'événement avec Xèdo: 
            <ol>
                <li>Recherchez l'événement souhaité dans l'application (Scanner le Qr code de l'évènement depuis l'onglet "Billeterie" ) </li>
                <li>Sélectionner le type de ticket puis entrer la quantité désirée</li>
                <li>Procédez au paiement en utilisant votre portefeuille Xèdo, une fois confirmé, les billets seront disponible dans l'onglet "Billeterie"</li>
                <li>Procéder à l'attribution de ticket. Cliquer sur le ticket puis suivez les instructions.</li>
            </ol>
        </>
    },
    {
        ask: "Comment utiliser la billeterie de Xèdo pour mon événement?",
        answer: "Contacter-nous pour nous faire part des détails de votre évènement."
    },
    {
        ask: "Comment puis-je contacter le support client de Xèdo ?",
        answer: <> Contacter-nous en cliquant sur "Contact" présent dans le menu ou via l'application Xèdo depuis l'onglet "Profile"</>
    },
    {
        ask: "Comment puis-je suivre mes transactions et mon historique avec Xèdo ?",
        answer: <>L'onglet "Activité" de l'application Xèdo vous permet de consulter toutes vos transactions passées, y compris les transferts d'argent et les achats de billets d'événements. Vous pouvez également générer des statistiques pour un suivi détaillé.</>
    },
    {
        ask: "Xèdo est-il disponible dans d'autres pays en dehors de l'Afrique ?",
        answer: "Actuellement, Xèdo est principalement axé sur les pays d'Afrique, mais nous travaillons sur l'expansion de nos services. Restez à l'écoute pour des mises à jour sur de nouveaux marchés."
    }
]

export default FAQ;

