import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import "../styles/index.scss";
import Readme from "./Readme";
import Notification from "../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addNotification } from "../store/action/notification";
import Event from "./Event";



const Pages = ()=>{
	const {errors: {detail}} = useSelector(state=>state.alert);
	const dispatch = useDispatch();

	useEffect(()=>{
		detail && dispatch(addNotification({message: detail}, 5000));
	}, [detail, dispatch])

    return (
        <BrowserRouter>
			<Routes>
				<Route path="" element={<Home/>} />
				<Route path="readme" element={<Readme/>}/>
				<Route path="event/:slug" element={<Event/>}/>
				<Route path='*' element={<Home/>}/>
			</Routes>
			<Notification/>
		</BrowserRouter>
    )
}

export default Pages;
