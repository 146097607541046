export const HOST = 
// "http://127.0.0.1:8000/";
// "http://192.168.43.185:8000/";
// "http://192.168.1.115:8000/";
// "http://172.20.10.13:8000/";
// "http://192.168.1.103:8000/";
"https://api.xedo.projetsmart.com/";


export const APP_HOST = 
// "http://127.0.0.1:3000/";
// "http://192.168.43.185:3000/";
// "http://192.168.1.115:3000/";
// "http://172.20.10.13:3000/";
// "http://192.168.1.103:3000/";
"https://xedo.projetsmart.com/";


// export const WS_HOST = 
// "ws://127.0.0.1:8000/";
// // "ws://192.168.43.185:8000/";
// // "ws://192.168.1.115:8000/";
// // "ws://172.20.10.13:8000/";
// // "ws://192.168.1.103:8000/";

export const CONTACT = "platform/messages/";
export const EVENT = slug => `events/${slug}/`;

