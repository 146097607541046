import { useSearchParams } from "react-router-dom";
import HideChildren from "../components/HideChildren";
import Menu from "../components/Menu";


const Readme = ()=>{

    const [search, setSearch] = useSearchParams();


    return(
        <div className="readme">
            <Menu/>
            <HideChildren 
                title={<h2>Conditions d'utilisation XEDO</h2>}
                name="condition" 
                open={search.get("to")}
                toggleOpen={v=>setSearch({to: v})}
            >
                <p>
                    Les présentes conditions d'utilisation de la plate-forme régissent votre accès et votre
                    utilisation de ce site Web. Ces Conditions s'appliquent à votre utilisation dans leur
                    intégralité et en utilisant cette Plateforme, vous acceptez expressément toutes les
                    Conditions dans leur intégralité. Vous ne devez pas utiliser cette plate-forme si vous
                    vous opposez à l'une de ces conditions.
                </p>

                <HideChildren title={<h4>Définitions</h4>}>
                    <p>
                        Compte: est le résultat de l'enregistrement dans le système informatique, au cours
                        duquel les données personnelles de la personne sont stockées, à la suite de cet
                        enregistrement, la personne reçoit un nom d'utilisateur et ses droits dans le système
                        sont définis.
                        Client : la personne physique ou morale qui a conclu le contrat de services XEDO.
                        Identification : vérification de l'identité du client ou du représentant de la personne
                        morale selon la procédure établie dans le système.
                        Consentement : Consentement du client à effectuer une transaction.
                        Tarification : prix des prestations et des opérations selon la procédure établie par
                        XEDO.
                    </p>
                </HideChildren>
                <HideChildren title={
                    <h3>
                        Veuillez lire et comprendre attentivement ces conditions avant d'utiliser la plateforme.
                    </h3>
                }>
                    <HideChildren title={<h4>Présentation de XEDO</h4>}>
                        <p>
                            Xèdo est un portefeuille numérique qui permet une gestion simple et efficace de vos
                            finances personnelles. Xèdo vous permet de payer vos achats et services dans
                            plusieurs pays d’Afrique.
                            Avec Xèdo vous bénéficiez de plusieurs avantages dont des réductions chez nos
                            partenaires et des entrées gratuites à divers évènements sponsorisés ( Divertissement ,
                            Entreprenariat , Culture , Sport ).
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Restriction d'âge</h4>}>
                        <p>
                            Notre plateforme et nos services s’adressent aux personnes de 18 ans et plus. Nous
                            n’exécutons pas les demandes d’inscription des personnes ayant moins de 18 ans.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Propriété intellectuelle</h4>}>
                        <p>
                            L’utilisateur reconnaît expressément que le site et les contenus (images,
                            photographies, marques, logos, articles, urls, gifts, …) sont la propriété exclusive de
                            l’éditeur et sont protégés par le code de la propriété intellectuelle béninoise ainsi que
                            les traités et les accords internationaux applicables en la matière. En conséquence,
                            toute reproduction, représentation, adaptation, traduction et/ou transformation
                            partielle ou intégrale, ou transfert vers un autre site, sans l’autorisation écrite,
                            expresse et préalable de l’éditeur, sont interdits.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Notifications qui vous sont destinées</h4>}>
                        <p>
                            Toute notification ou toute autre information de XEDO, vous sera envoyée à votre
                            adresse email ou en vous appelant au téléphone ou encore en vous envoyant un SMS.
                            Excepté les notifications relatives aux modifications apportées au présent contrat,
                            nous considérons que vous aurez reçu toute notification que nous vous aurons
                            envoyée dans les 24 heures suivant son envoi par email ou SMS.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Notifications destinées à XEDO</h4>}>
                        <p>
                            Toute notification envoyée à XEDO, dans le cadre du présent contrat doit être
                            adressée par courrier électronique par le biais de l’interface de communication client
                            Les réclamations sont traitées dans l’intervalle de 48 heures ouvrables.
                            Historiques des transactions
                            Vous pouvez accéder aux détails des transactions exécutées et à d’autres informations
                            relatives à l’historique de vos transactions dans votre compte.
                            Modifications du présent contrat sur les conditions générales d'utilisations
                            Nous pouvons à tout moment modifier le présent contrat ou le règlement sur le respect
                            de la vie privée, supprimer certaines de ses clauses ou en ajouter de nouvelles, y
                            compris les tarifs de transactions de XEDO.Toute modification sera faite de manière
                            unilatérale par XEDO et vous serez réputé avoir accepté les modifications après en
                            avoir reçu notification.Nous vous informerons des changements avant qu'ils n'entrent
                            en vigueur, à moins que les changements ne soient requis par la loi, ou ne concernent
                            l'ajout d'un nouveau service ou d'une nouvelle fonctionnalité aux services existants,
                            ou d'autres changements dont nous pensons raisonnablement qu'ils se produiront, ils
                            ne sont pas affectés. Vos droits ou augmenter vos responsabilités, auquel cas les
                            modifications seront apportées sans préavis et entreront en vigueur immédiatement.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Activités interdites</h4>}>
                        <p>
                            XEDO ne doit être utilisé en aucun cas pour des activités :
                            qui violent une loi, un contrat, une ordonnance ou une réglementation
                            en lien avec des transactions impliquant des produits qui présentent un risque pour la
                            sécurité du consommateur ;des drogues et objets associés ;des objets illégaux ou
                            objets visant à encourager, promouvoir et faciliter des activités illégales ou à
                            expliquer à d’autres personnes comment les mener; des objets qui prônent la haine, la
                            violence, l’intolérance raciale ou l’exploitation financière d’actes criminels ; des
                            objets pouvant être considérés de nature obscène;
                            qui impliquent la vente de produits ou services identifiés par les agences
                            gouvernementales comme présentant une forte probabilité de fraude;
                            en relation et/ou en financement du terrorisme.
                        </p>
                    </HideChildren>
                    
                    
                    <HideChildren title={<h4>Accessibilité</h4>}>
                        <p>
                            XEDO est accessible à tout le monde, c’est-à-dire particulier et entreprise.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Éligibilité</h4>}>
                        <p>
                            Cette section indique l’éligibilité des clients sur la plateforme. Elle concerne à la fois
                            les personnes physiques et morales et se résume comme suit :
                            Pour devenir client de XEDO, il est indispensable de créer et faire vérifier son compte
                            avec une pièce d'identité (passeport ou carte d'identité nationale, carte LEPI, permis
                            de conduire).
                            Un client ne peut avoir plus d’un identifiant sur la plateforme. En tout état de cause,
                            l’administration de XEDO se donne le droit de supprimer selon son choix les comptes
                            supplémentaires.
                        </p>
                    </HideChildren>
                    
                    
                    <HideChildren title={<h4>Responsabilités garanties</h4>}>
                        <p>
                            Vous êtes responsable de toutes les réclamations, amendes que nous encourons
                            découlant de :
                            toute erreur, négligence, faute ou fraude de votre part, de vos employés ou de toute
                            personne agissant en votre nom;
                            toute perte résultant de votre non-respect des conditions du présent contrat ou de votre
                            utilisation des services de XEDO
                            Le client a la responsabilité totale de son identifiant. Il doit garder l’accès secret et en
                            faire un usage personnel. XEDO ne répond pas d’une utilisation frauduleuse de
                            l’adresse d’un client et décline toute responsabilité.
                            Inscription de l'utilisateur
                            Se rendre sur www.xedo.bj remplir le formulaire et suivre les instructions.
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Loi applicable</h4>}>
                        <p>
                            Le non-respect par l’utilisateur, quelle que soit sa localisation, de l’une quelconque
                            des dispositions des présentes conditions générales d’utilisation et ou de leur
                            exécution, interprétation ou validité, sont soumis à la loi et aux juridictions de la cour
                            commune de justice et d’arbitrage de l’OHADA.
                        </p>
                    </HideChildren>
                    <HideChildren title={<h4>Mise à jour des conditions générales d'utilisations</h4>}>
                        <p>
                            L’éditeur se réserve la possibilité, à tout moment et sans préavis, de modifier et de
                            mettre à jour les présentes conditions générales d’utilisation.
                        </p>
                    </HideChildren>
                    
                </HideChildren>
                    
            </HideChildren>

            <HideChildren 
                title={<h2>Politique de confidentialité</h2>}
                name="politique" 
                open={search.get("to")}
                toggleOpen={v=>setSearch({to: v})}
            >
                <p>
                    Dans sa vocation à proposer des solutions alternatives à fort impact social,
                    permettant de lutter contre l’exclusion financière le site Xèdo.bj assure
                    l’exploitation dans le cadre de ses activités, met tout en œuvre afin d’assurer
                    la sécurité et la confidentialité des informations qu’elle recueille. En outre, elle
                    fait usage de « cookies » afin de permettre à l’utilisateur de vivre une
                    meilleure expérience lors de sa navigation sur le site.
                    Fort de ce constat, la présente politique de confidentialité des données et
                    d’utilisation des cookies (ci-après la politique), explicite les engagements mis
                    en Œuvre par Xèdo en tant que responsable du traitement des données, afin
                    d’assurer leur protection, leur confidentialité tout fournissant la meilleure
                    expérience d’utilisation possible.
                    Cette politique est le prolongement des conditions générales d’utilisation,
                    reprenant ainsi de facto, la définition des termes essentiels de cette dernière.
                    La modification des présentes, reste à la discrétion exclusive de Xèdo, la
                    version actuelle disponible sur le site est celle en vigueur.
                </p>
                <HideChildren title={<h3>De la confidentialité de vos données personnelles</h3>}>
                    <HideChildren title={<h4>Les personnes concernées par la présente</h4>}>
                        <p>
                            La présente politique s’applique à toute personne physique ayant recours aux
                            services de Xèdo et de ses applications disponibles sur Google Play Store et
                            sur l’App Store, leur permettant de créer un compte, de rejoindre un pool aux
                            fins d’effectuer des opérations de transfert d’argent ou d’achat de service.
                            Plus généralement, cette politique s’applique non seulement à toutes
                            personnes susmentionnées, mais également aux partenaires et aux visiteurs
                            du site Xèdo.
                        </p>
                    </HideChildren>
                    <HideChildren title={<h4>Les données collectées</h4>}>
                        <p>
                            Conformément à l’objet susmentionné, en recourant aux services et solutions
                            proposées par Xèdo, vous acceptez le cas échéant leur traitement par cette
                            dernière. En ce sens, Xèdo procédera à la collecte des données ci-après :
                        </p>
                        <ul>
                            <li>Celles nécessaires à votre identification (subordonnées ainsi à la fourniture de pièces justificatives)</li>
                            <li>Celles relatives à transactions sur ses application (paiement entrant, paiement sortant)</li>
                            <li>Celles permettant l’analyse de vos comportements sur le site afin de l’améliorer</li>
                            <li>Celles issues de vos échanges avec Xèdo (e-mails, chat, courrier, etc.)</li>
                            <li>Celles nécessaires au bon fonctionnement du site dans sa globalité</li>
                            <li>
                                Et plus généralement toutes celles obtenues avec votre accord et/ou en
                                accord avec la réglementation en vigueur ou en accord avec les exigences de
                                cette dernière.
                                La mise à disposition de certaines données est la condition sine qua non au
                                bon fonctionnement du site. La non-fourniture de ces données empêche alors
                                l’utilisateur d’y accéder.
                            </li>
                        </ul>
                    </HideChildren>
                    <HideChildren title={<h4>Les finalités de traitement des données</h4>}>
                        <p>
                            Cette présente étant le prolongement des conditions générales d’utilisations
                            (C.G.U.), les données collectées rentrent ainsi dans l’exécution de ladite
                            C.G.U. Au titre des finalités, nous avons notamment : 
                        </p>
                        <ul>
                            <li>
                                L’utilisation entière des services fournis par le site et/ou l’application
                                notamment par la création d’un compte personnel, l’admission dans un pool
                                et l’utilisation de l’ensemble d’autres fonctionnalités proposées par Xèdo.
                            </li>
                            <li>
                                L’évaluation de votre profil en termes de solvabilité avec notamment la
                                détermination de votre indice de crédit
                            </li>
                            <li>
                                Le respect des obligations légales et réglementaires : lutte contre la fraude, le
                                blanchiment d’argent, l’usurpation d’identité, etc.
                            </li>
                            <li>
                                La sécurisation de l’utilisation du site, des transactions et plus généralement
                                la sécurisation des diverses interactions dans les pools
                            </li>
                            <li>L’amélioration de notre position et ciblage marketing.</li>
                        </ul>
                    </HideChildren>
                    <HideChildren title={<h4>Les destinataires de vos données (les personnes ayant accès) à vos données</h4>}>
                        <p>
                            Xèdo, s’engage à ne jamais monnayer vos données à caractères
                            personnels de quelque manière que ce soit, cela ne faisant pas partir de son
                            modèle économique et de ses vocations.
                            Néanmoins, pour les besoins et pour le bon fonctionnement de nos services,
                            le participant donne son consentement au partage de certaines de ses
                            données personnelles (nom, prénom, indice de solvabilité et de confiance,
                            etc.) avec les autres participants à un pool.
                            En outre, pour motif de transparence, une communication sera effectuée sur
                            les bénéficiaires des différents pools chaque fois que ceux-ci seront connues.
                            Cette communication sera faite sur les réseaux sociaux par notre page.
                            Enfin, du fait de la réglementation en vigueur, notamment celle issue de la
                            lutte contre la fraude et le blanchiment d’argent certaines de vos données
                            telles que : vos noms et prénoms, justificatifs d’identité, adresse, numéros de
                            téléphones sont susceptibles d’être transmis à la PLCC ( Plateforme de lutte
                            contre la cybercriminalité) et à toutes les autorités auxquelles la loi donne
                            compétence pour besoin d’enquête, le cas échéant, conformément à notre
                            C.G.U.
                        </p>
                        <p>
                            Afin d’assurer la sécurité et la confidentialité de vos données collectées par
                            nos services, nous utilisons les technologies SSL
                        </p>
                    </HideChildren>
                    
                    <HideChildren title={<h4>Délai de conservation de vos données</h4>}>
                        <p>
                            Pour assurer le bon déroulement des différentes opérations et
                            particulièrement le bon fonctionnement des pools dans lesquelles vous êtes
                            membres, vos données personnelles feront obligatoirement l’objet d’une
                            conservation temporaire et de mise à jour régulière.
                            Le site, pourra conserver pendant une période maximale de 5 ans vos
                            données personnelles au-delà de la date de la dernière activité afin de
                            satisfaire le cas échéant des exigences légales, réglementaires, des besoins
                            de poursuite nés postérieurement à la suppression desdites données.
                            À des fins statistiques, certaines de vos données pourront être conservées de
                            façon totalement anonyme.
                        </p>
                    </HideChildren>
                    <HideChildren title={<h4>Vos droits</h4>}>
                        <p>
                            Conformément aux dispositions de la loi, n°78-17 relative à l’informatique,
                            aux fichiers et aux libertés du 6 janvier 1978 et conformément à la
                            réglementation générale sur la protection des données, vous bénéficiez d’un
                            droit : 
                        </p>
                        <ul>
                            <li>D’accès à vos données</li>
                            <li>De modification de vos données</li>
                            <li>De limitation des traitements portant sur vos données</li>
                            <li>À la portabilité de vos données</li>
                            <li>
                                Et même de déterminer la finalité de vos données en cas de décès
                                Ces droits doivent être mis en œuvre dans les conditions et limites des lois
                                susmentionnées.
                                Pour une mise en œuvre efficiente de ces droits, il vous faudra contacter le
                                site via l’onglet « nous contacter »
                                Le cas échéant, vous avez également le droit de saisir par le biais d’une
                                réclamation la Cnil (commission national de l’informatique et des libertés),
                                qui est l’autorité administrative en charge de veiller quant au respect et la
                                protection des données à caractère personnel.
                            </li>
                        </ul>
                    </HideChildren>
                
                </HideChildren>
                <HideChildren title={<h3>De l’utilisation des cookies</h3>}>
                    <HideChildren title={<h4>Raison d’existence et définition</h4>}>
                        <p>
                            Pour les besoins exclusifs de notre service, nous vous informons que nous
                            utilisons des cookies (témoins de connexion), qui sont de petits fichiers texte
                            enregistrés automatiquement par votre navigateur sur votre terminal de
                            connexion (ordinateur, smartphone, ou tablette). Ces cookies sont essentiels
                            et nous permettent d’analyser et d’optimiser notre site afin de vous proposer
                            la meilleure expérience de navigation possible.
                        </p>
                    </HideChildren>
                    <HideChildren title={<h4>Réglementation applicable</h4>}>
                        <p>
                            Au sens du Règlement Général de la Protection des Données (R.G.P.D.) ,
                            l’utilisation de cookies non-impératifs pour le fonctionnement du site implique
                            nécessairement l’obtention du consentement de l’utilisateur. En ce sens, dans
                            bien des cas, vous serez amenés à vous prononcer en donnant votre
                            consentement ou non à l’utilisation de ces dits cookies. Ces cookies ou
                            témoins de connexion pourront être conservés après enregistrement au
                            maximum 13 mois sur votre matériel de connexion.
                        </p>
                    </HideChildren>
                    <HideChildren title={<h4>Les cookies présents sur notre site</h4>}>
                        <p>
                            Sur notre site, nous utilisons une variété de cookies : certains sont
                            indispensables à l’utilisation et le bon fonctionnement de notre site, d’autres le
                            sont moins, mais permettent tous d’améliorer l’expérience et la qualité de la
                            navigation de l’utilisation, ainsi nous avons notamment :

                        </p>
                        <ul>
                            <li>
                                Les cookies techniques : qui permettent de vous identifier, de sécuriser
                                l’accès à votre compte personne, de créer un pool, de rejoindre un pool,
                                d’identifier les membres d’un pool, d’identifier un pool, etc.
                            </li>
                            <li>
                                Les cookies fonctionnels : qui permettent d’améliorer la qualité de notre site et
                                votre expérience en tant qu’utilisateur. Ainsi, ces cookies permettent par
                                exemple de connaître le pays de connexion de l’utilisateur, de changer la
                                langue, le design, le graphique, et même la présentation et l’organisation
                                générale du site en fonction du terminal de connexion choisi, permettant ainsi
                                une navigation fluide et individualisée.
                            </li>
                            <li>
                                Les cookies d’analyse : l’objectif étant de vous offrir le meilleur service
                                possible, ces cookies permettent de connaître le nombre d’utilisateurs, le
                                nombre de visiteurs, connaître votre degré d’interaction et d’intérêt avec telle
                                ou telle autre page
                            </li>
                            <li>
                                Les cookies publicitaires : sur notre site, nous utilisons aussi des cookies
                                publicitaires, qui ont pour finalité de permettre à nos partenaires commerciaux
                                de pouvoir amener leurs utilisateurs sur notre site. En outre, ils permettent
                                selon vos profils de vous présenter des offres et solutions plus en adéquation
                                avec vos centres d’intérêts
                            </li>
                            <li>
                                Les cookies dédiés au Réseaux sociaux : sur notre site, vous avez des
                                boutons, qui permettent de partager votre contenu sur certains réseaux
                                sociaux via des » boutons ». Avant d’utiliser ces boutons de partage, nous
                                vous invitons vivement à lire en amont la politique de cookies de ces
                                différents réseaux sociaux
                            </li>
                        </ul>
                    </HideChildren>
                </HideChildren>
                <HideChildren title={<h3>Droit d’accès et de désactivation des cookies</h3>}>
                    <p>
                        Au égard de leur importance, généralement les cookies sont installés
                        directement par défaut sur votre terminal de connexion.
                        Néanmoins, il est toujours possible de refuser l’installation de certains
                        cookies, et même de faire le tri entre les cookies que vous acceptez ou non.
                        Pour mieux appréhender la façon de bloquer ou choisir le cookie qui vous
                        convient, nous vous conseillons ce <span> </span>
                        <a href="https://openclassrooms.com/fr/courses/2807371-controlez-lutilisation-de-vos-
                        donnees-personnelles/3015131-configurez-les-cookies-sur-votre-navigateur">tutoriel
                        </a>.
                        
                        Le refus de certains cookies peut altérer le bon fonctionnement de notre site
                        et/ou même le rendre complètement inaccessible. Le cas échéant, le site
                        décline toute responsabilité qui pourrait en résulter. 
                    </p>
                </HideChildren>
            </HideChildren>

        </div>
    )

}

export default Readme;
