import { motion} from "framer-motion";
import { useCallback, useMemo, useState } from "react";



const HideChildren = ({title, children, open, toggleOpen, name})=>{
    
    const [opnd, setOpnd] = useState(false);
    
    const opened = useMemo(()=>name !== undefined? open === name: opnd, [open, name, opnd]);

    const handleToggle = useCallback(()=>{
        toggleOpen? toggleOpen(name===open? null: name): setOpnd(v=>!v)
    }, [toggleOpen, name, open])

    return (
        <motion.div className={`toggle-children ${opened? "show": "hide"}`} id={name || ""}>
            <motion.div className="title" onClick={handleToggle} style={{cursor: "pointer"}}>
                <div style={{flex: 1}}>
                    {title}
                </div>
                <motion.span animate={{transform: opened? "rotate(-90deg)" :"rotate(180deg)"}} style={{width: "2em", height: "2em"}}>
                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                </motion.span>
            </motion.div>
            
            {/* <AnimatePresence>

                {opened && ( */}
                    <motion.div className="children" 
                        initial="close"
                        animate={opened?"open": "close"}

                        style={{overflow: "hidden"}}
                        
                        variants={{
                            open: {
                                opacity:1,
                                scale: 1,
                                height: null,
                            },
                            close:{
                                opacity: 0,
                                scale: .7, height: 0
                            }
                        }}
                    >
                        {children}
                    </motion.div>
                {/* )}
            </AnimatePresence> */}
        </motion.div>
    )
}

export default HideChildren;